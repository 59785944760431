import { createBrowserRouter } from "react-router-dom";
import QRLanding from "./screens/QRLanding";
import { ConfirmationPage } from "./screens/ConfirmationPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <QRLanding />,
  },
  {
    path: "/confirmation",
    element: <ConfirmationPage />,
  },
]);

export default router;
