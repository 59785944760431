import { CircularProgress } from "@mui/material";

export function Loading() {
  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center bg-[rgba(200,200,200,0.2)] backdrop-blur-sm">
      <CircularProgress
        sx={{
          color: "#ef0bb8",
        }}
        className="animate-spin h-10 w-10 text-primary"
      />
    </div>
  );
}
