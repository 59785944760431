import BaseService from "./BaseService";
import { RequestDto } from "./dtos/request/RequestDto";
import { GetEmployeeRequestDto } from "./dtos/request/userRequestDto";
import { GetEmployeeResponseDto } from "./dtos/response/UserResponseDto";

class UserService extends BaseService {
  private resource = "/users";

  async getEmployee(
    dto: GetEmployeeRequestDto,
    onSuccess: (data: GetEmployeeResponseDto) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/employee/landing", dto);
    await this.doGet<GetEmployeeResponseDto>(reqParams, onSuccess, onError);
  }
}

export default UserService;
