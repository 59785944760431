import axios from "axios";
import { RequestDto } from "./dtos/request/RequestDto";
import { ResponseDto } from "./dtos/response/ResponseDto";

class BaseService {
  private domainEndpoint: string = process.env.REACT_APP_BASE_URL ?? "";

  private getUrlEndpoint(resource: string) {
    return this.domainEndpoint + resource;
  }

  protected async doPost<T>(
    dto: RequestDto,
    postRecover: (responseDto: T) => void,
    errorRecover: (errorMessage: string) => void
  ): Promise<void> {
    try {
      const { data, status } = await axios.post<T>(
        this.getUrlEndpoint(dto.resource),
        dto.data,
        {
          headers: dto.headers,
          validateStatus: (status) => status < 500,
        }
      );
      const response = this.GetResponseData<T>(data, status);
      console.log(response);

      if (response.ErrorCode !== 0) {
        errorRecover(response.ErrorMessage || "Hubo un error inesperado.");
      } else {
        postRecover(response.Data);
      }
    } catch (error: any) {
      errorRecover("Hubo un error inesperado.");
    }
  }

  protected async doGet<T>(
    dto: RequestDto,
    postRecover: (responseDto: T) => void,
    errorRecover: (errorMessage: string) => void
  ): Promise<void> {
    try {
      const { data, status } = await axios.get<T>(
        this.getUrlEndpoint(dto.resource),
        { params: dto.data, validateStatus: (status) => status < 500 }
      );
      const response = this.GetResponseData<T>(data, status);

      if (response.ErrorCode !== 0) {
        errorRecover(response.ErrorMessage || "Hubo un error inesperado.");
      } else {
        postRecover(response.Data);
      }
    } catch (error: any) {
      errorRecover("Hubo un error inesperado, inténtelo más tarde.");
    }
  }

  private GetResponseData<T>(data: any, status: number): ResponseDto<T> {
    const response = new ResponseDto<T>();

    response.code = status;
    response.ErrorCode = data.ErrorCode;
    response.ErrorMessage = data.ErrorMessage;
    response.Data = data.Data;

    return response;
  }
}

export default BaseService;
