export class InitPaymentRequestDto {
  public TargetOrigins!: string;
  public Amount!: number;

  constructor(amount: number, targetOrigins: string) {
    this.Amount = amount;
    this.TargetOrigins = targetOrigins;
  }
}

export class AuthorizePaymentRequestDto {
  public Token!: string;
  public Amount!: number;
  public EmployeeId!: number;
  public BranchId!: number;
  public RatingMessage!: string | null;
  public RatingValue!: number | null;

  constructor(token: string) {
    this.Token = token;
  }
}

export class WithoutTipRequestDto {
  public EmployeeId!: number;
  public BranchId!: number;
  public RatingValue?: number;
  public RatingMessage?: string | null;

  public constructor(
    employeeId: number,
    branchId: number,
    ratingValue?: number,
    ratingMessage?: string | null
  ) {
    this.EmployeeId = employeeId;
    this.BranchId = branchId;
    this.RatingValue = ratingValue;
    this.RatingMessage = ratingMessage;
  }
}
