import React from 'react';
import './App.css';
import { RouterProvider } from "react-router-dom";
import "./styles/styles.css";
import router from './Router';

const App = () => {
	return (
				<RouterProvider router={router} />
	);
};

export default App;
