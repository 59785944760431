import { useLocation } from "react-router-dom";
import DivixLogo from "../assets/img/divixNegro.png";

interface ConfirmationState {
  rating: number;
  googleLink: string | null | undefined;
}

export function ConfirmationPage() {
  const location = useLocation();
  const state = location.state as ConfirmationState | undefined;

  return (
    <div className="flex flex-col   min-h-screen">
      <div className="h-56 flex items-center justify-center pt-20 bg-[#e0e0e0]">
        <img className="mx-auto w-24 " src={DivixLogo} alt="Divix Logo" />
      </div>
      <div className="text-center mt-12  mx-auto max-w-md">
        <h1 className="text-[#3a3a3a] text-5xl  mb-4">¡Gracias!</h1>
        <div className="max-w-60 mx-auto pt-2 pb-10">
          <span className="text-[#3a3a3a] text-xl text-center">
            Tu valoración nos sirve para mejorar.
          </span>
        </div>
        <a
          className="text-white inline-flex mb-4 bg-primary rounded-full py-1 px-3"
          href="https://www.divix.net"
        >
          www.divix.net
        </a>

        {state?.googleLink && state.rating >= 4 && (
          <div className="border-t border-[#e0e0e0] pt-4 mt-4">
            <h2 className="text-[#3a3a3a] text-lg mb-4">
              Deja tu opinión en Google
            </h2>
            <a
              href={state.googleLink}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center justify-center text-black border border-[#4285F4] rounded-full py-2 px-6 hover:bg-[#4285F4]/10 transition-colors"
            >
              <svg
                className="w-5 h-5 mr-2"
                viewBox="-0.5 0 48 48"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path
                    d="M9.827 24c0-1.524.253-2.986.705-4.356l-7.909-6.04A23.456 23.456 0 0 0 .213 24c0 3.737.868 7.26 2.407 10.388l7.905-6.05A13.885 13.885 0 0 1 9.827 24"
                    fill="#FBBC05"
                  />
                  <path
                    d="M23.714 10.133c3.311 0 6.302 1.174 8.652 3.094L39.202 6.4C35.036 2.773 29.695.533 23.714.533a23.43 23.43 0 0 0-21.09 13.071l7.908 6.04a13.849 13.849 0 0 1 13.182-9.51"
                    fill="#EB4335"
                  />
                  <path
                    d="M23.714 37.867a13.849 13.849 0 0 1-13.182-9.51l-7.909 6.038a23.43 23.43 0 0 0 21.09 13.072c5.732 0 11.205-2.036 15.312-5.849l-7.507-5.804c-2.118 1.335-4.786 2.053-7.804 2.053"
                    fill="#34A853"
                  />
                  <path
                    d="M46.145 24c0-1.387-.213-2.88-.534-4.267H23.714V28.8h12.604c-.63 3.091-2.346 5.468-4.8 7.014l7.507 5.804c4.314-4.004 7.12-9.969 7.12-17.618"
                    fill="#4285F4"
                  />
                </g>
              </svg>
              Valorar en Google
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
