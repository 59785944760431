import { TextField } from "@mui/material";
import { ChangeEvent } from "react";

interface InputCommentProps {
  onChange: (value: string) => void;
  value: string | null;
  maxCharacters: number;
}

export function InputComment({
  onChange,
  value,
  maxCharacters,
}: InputCommentProps) {
  const remainingCharacters = !value
    ? maxCharacters
    : maxCharacters - value.length;

  return (
    <div className="relative">
      <TextField
        name="comment"
        placeholder="Contanos tu experiencia"
        translate="no"
        multiline
        value={value ?? ""}
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
          onChange(e.target.value)
        }
        sx={{
          "& fieldset": {
            paddingLeft: (theme) => theme.spacing(2.5),
            borderRadius: "30px",
          },
          "& .MuiOutlinedInput-root": {
            fontFamily: "Montserrat",
            color: "rgb(66, 66, 66)",
          },
        }}
        rows={3}
        fullWidth
        variant="outlined"
      />
      <p className="text-xs text-right mt-1 mr-1 text-[#424242]">
        {remainingCharacters} caracteres restantes
      </p>
    </div>
  );
}
