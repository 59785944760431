import BaseService from "./BaseService";
import { RequestDto } from "./dtos/request/RequestDto";
import {
  InitPaymentRequestDto,
  AuthorizePaymentRequestDto,
  WithoutTipRequestDto,
} from "./dtos/request/tipRequestDto";
import { InitPaymentResponseDto } from "./dtos/response/TipResponseDto";

class TipService extends BaseService {
  private resource = "/tips";

  public SendTip(
    dto: any,
    postSucess: (data: any) => void,
    postError: (errorMessage: string) => void
  ): void {
    const reqParams = new RequestDto(this.resource + "/Send", dto);
    this.doPost<any>(reqParams, postSucess, postError);
  }

  public InitPayment(
    dto: InitPaymentRequestDto,
    postSucess: (data: InitPaymentResponseDto) => void,
    postError: (errorMessage: string) => void
  ): void {
    const reqParams = new RequestDto(this.resource + "/init-payment", dto);
    this.doPost<any>(reqParams, postSucess, postError);
  }

  public pay(
    dto: AuthorizePaymentRequestDto,
    postSucess: (data: any) => void,
    postError: (errorMessage: string) => void
  ): void {
    const reqParams = new RequestDto(this.resource + "/pay", dto);
    this.doPost<any>(reqParams, postSucess, postError);
  }

  async withoutTip(
    dto: WithoutTipRequestDto,
    onSuccess: (data: null) => void,
    onError: (errorMessage: string) => void
  ) {
    const reqParams = new RequestDto(this.resource + "/rating/process", dto);
    this.doPost<any>(reqParams, onSuccess, onError);
  }
}

export default TipService;
