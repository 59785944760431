import { ChangeEvent } from "react";

interface InputAmountProps {
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  amount: string;
}

const InputAmount = ({ setAmount, amount }: InputAmountProps) => {
  return (
    <>
      <span className="text-[#e0e0e0] ml-2">$</span>
      <input
        name="amount"
        type="number"
        value={amount}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setAmount(e.target.value)
        }
        className="ml-1 pr-2 py-2 text-center focus:outline-none flex-grow"
        style={{
          width: `${Math.max(amount.length + 1, 2)}ch`,
          minWidth: "2ch",
          maxWidth: "12ch",
        }}
        step="0.01"
        min="0"
        placeholder="0"
      />
    </>
  );
};

export default InputAmount;
